@import "../../index.scss";

.portfolio-resume-page {
    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 3rem;

        @media screen and #{$mq-max-tablet} {
            padding: 0 2rem;
        }

        @media screen and #{$mq-max-mobile} {
            padding: 0 0rem;
        }
    }

    &__title--active {
        cursor: default;
        visibility: hidden;
    }

    &__title--not-active {
        cursor: pointer;
    }

    &__header {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 1rem;
        margin-bottom: 3rem;
    }

    &__full-name {
        font-weight: bold;
        font-size: 4rem;
        line-height: 3.2rem;
        margin-bottom: 1rem;
    }

    &__position {
        font-size: 2rem;
    }

    &__contacts {
        &--focus {
            animation: magnify 1s linear normal forwards;
        }
    }

    @keyframes magnify {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1);
        }

        75% {
            transform: scale(1.3);
        }

        100% {
            transform: scale(1);
        }
    }

    &__details-position {
        font-weight: bold;
        font-size: 2rem;
        line-height: 3rem;
    }

    &__no-link {
        a {
            text-decoration: none;
            color: initial;
            cursor: pointer;
        }
    }

    &__gap1 {
        display: flex;
        gap: 0.7rem;
        align-items: center;
    }

    &__grid-section {
        display: grid;
        grid-template-columns: 3fr 2fr;
        row-gap: 3rem;
        margin-bottom: 3rem;

        @media screen and #{$mq-max-tablet} {
            grid-template-columns: 2fr 1fr;
        }

        @media screen and #{$mq-max-small-tablet} {
            grid-template-columns: 1fr;
        }
    }

    &__grid-item--left,
    &__grid-item--right,
    &__paragraph-full-width {
        border-left: 2px solid grey;
    }

    &__grid-item--left {
        padding: 0 1rem;
        margin: 0 1rem;
    }

    &__grid-item--right {
        padding: 0 0 0 1rem;
        margin: 0 0 0 1rem;
    }

    &__paragraph-full-width {
        padding: 0 1rem;
        margin: 0 1rem;
        margin-bottom: 3rem;
    }

    &__detail {
        display: flex;
        flex-direction: column;
        width: 100%;

    }

    &__detail:not(:last-child) {
        margin-bottom: 1rem;
    }

    &__detail-title {
        font-weight: bold;
        text-transform: uppercase;
    }
}
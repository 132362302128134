.title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;

    &--dark {
        color: #fff;
        background-color: black;

    }
}
.portfolio-download-file {
    display: flex;
    width: 100%;
    margin: 0 0 1rem 1rem;
    flex-direction: column;
    align-items: center;

    &__caption {
        margin-bottom: 1rem;
    }
}
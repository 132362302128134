html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

html {
    overflow-x: hidden;
}

.body-wrapper {
    min-height: 100vh;
    position: relative;
    z-index: 1;
}

.body-wrapper::before {
    content: '';
    background: rgb(2, 0, 36);
    background: radial-gradient(circle, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 1) 35%, rgba(0, 212, 255, 1) 100%);
    background-size: cover;
    background-position: center center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

$mq-max-small-mobile: '(max-width: 23em)'; // 384px / base value of 16px
$mq-max-mobile: '(max-width: 26,25em)'; // 420px / base value of 16px
$mq-max-small-tablet: '(max-width: 31.25em)'; // 500px / base value of 16px
$mq-max-tablet: '(max-width: 48.0625em)'; // 769px / base value of 16px
$mq-max-small-desktop: '(max-width: 60em)'; // 960px / base value of 16px
$mq-max-desktop: '(max-width: 75em)'; // 1200px / base value of 16px
.portfolio-details-section {

    &__title {
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: 1rem;
    }


}
$pageScale: 0.7;
$topPosition: 50px;
$animTime: 0.5s;

.page-left-fadeIn {
    animation: left-fadeIn $animTime linear normal forwards;
}

@keyframes left-fadeIn {
    0% {
        z-index: 0;
        // transform: scale($pageScale);
        top: 0;
        left: 0;
    }

    30% {
        transform: translateX(-700px);
        z-index: 0;
    }

    50% {
        z-index: 2;
    }

    100% {
        transform: translateX(0px);
        transform: scale(1);
        z-index: 2;
        top: $topPosition;
        left: 0;
    }
}

.page-right-fadeOut {
    animation: right-fadeOut $animTime linear normal forwards;
}

@keyframes right-fadeOut {
    0% {
        z-index: 1;
        transform: scale(1);
        top: $topPosition;
        right: 0;

    }

    100% {
        transform: scale($pageScale);
        transform-origin: top;
        z-index: 0;
        top: 0;
        right: 0;
    }
}

.page-right-fadeIn {
    animation: right-fadeIn $animTime linear normal forwards;
}

@keyframes right-fadeIn {
    0% {
        z-index: 0;
        top: 0;
        right: 0;
    }

    30% {
        transform: translateX(700px);
        z-index: 0;

    }

    50% {
        z-index: 2;
    }

    100% {
        transform: translateX(0px);
        transform: scale(1);
        z-index: 2;
        top: $topPosition;
        right: 0;
    }
}

.page-left-fadeOut {
    animation: left-fadeOut $animTime linear normal forwards;
}

@keyframes left-fadeOut {
    0% {
        z-index: 1;
        transform: scale(1);
        top: $topPosition;
        left: 0;
    }

    100% {
        transform: scale($pageScale);
        transform-origin: top;
        z-index: 0;
        top: 0;
        left: 0;
    }
}

.cursor-zoom {
    cursor: zoom-in;
}
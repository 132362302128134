.welcome__img-holder {
    position: absolute;
    background: whitesmoke;
    width: 50px;
    height: 25px;
    transform: skewX(-25deg) translate(-50%, -50%);
    border-radius: 3px;
    opacity: 0.8;
    color: rgb(160, 160, 160);
    font-size: 14px;
}

.welcome__holder-dot {
    transform: translate(20%, 0%);
    font-weight: 100;
    line-height: 6px;
}

.welcome__top-holder {
    left: 0px;
    top: 0px;
}